<template>
  <div class="brand w-full pb-10">
    <div
      v-if="!banner.loading"
      class="collection-banner-container"
      :style="{
        'background-color': banner.data.background_color || 'rgb(121, 95, 70)',
      }"
    >
      <Banner
        :title="banner.data.name"
        :logo="imgBaseUrl(banner.data.image)"
        :toggle="true"
        @enabled="onStockChanged"
      >
        <template #header>
          <ol role="list" class="breadcrumb">
            <li>
              <div class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </div>
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span
                ><router-link to="/categories">Categories</router-link>
              </div>
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span
                ><a href="#" aria-current="page">{{ banner.data.name }}</a>
              </div>
            </li>
          </ol>
        </template>
        <template #footer>
          <div class="-mb-8">
            <Sorting @onSort="onSortChanged" />
            <SortFilterMobile @onSort="onSortChanged" />
          </div>
          <!-- Footer Slot -->
        </template>
      </Banner>
    </div>
    <div class="py-10 max-container">
      <!-- Listing -->
      <div class="flex items-center space-x-4 pb-5">
        <FilterBadge />
      </div>
      <ListLoaderWrapper :isLoading="category.loading" />
      <ItemsList
        :items="category"
        :loading="category.loading"
        :filter="filters"
        filter_by="brands"
      />
    </div>
    <ListLoaderWrapper :isLoading="category.onScrollLoading" />
  </div>
</template>

<script>
import Banner from "../common/Banner.vue";
import ItemsList from "../common/ItemsList.vue";
import Categories from "@/modules/Categories";
import ListLoaderWrapper from "@/components/loaders/ListLoaderWrapper.vue";
import { useStore, mapGetters } from "vuex";
import SortFilterMobile from "@/components/SortFilterMobile.vue";
import { watch, computed } from "vue";

export default {
  name: "Home",
  components: {
    Banner,
    ItemsList,
    ListLoaderWrapper,
    SortFilterMobile,
  },
  watch: {
    filters: {
      handler: "filterCategory",
      deep: true,
    },
    priceRange: {
      handler: "priceOnChange",
      deep: true,
    },
  },
  computed: {
    ...mapGetters({ filters: "getSelected" }),
    ...mapGetters({ priceRange: "getPriceRange" }),
  },
  methods: {
    filterCategory() {
      this.onFilterChanged();
    },
    priceOnChange() {
      if(this.$store.getters.getPriceCheck) this.onPriceRangeChanged();
    },
  },
  beforeMount() {
    this.$store.dispatch("getCart", { ax: null });
  },
  setup() {
    const store = useStore();
    const imgBaseUrl = (image) => {
      if (image === null) {
        return "/images/no-image.png";
      }
      const base_url_img = process.env.BASE_URL_IMG || "/";
      return base_url_img + "/" + image.path;
    };

    const {
      category,
      banner,
      getBanner,
      onSortChanged,
      onStockChanged,
      onFilterChanged,
      onPriceRangeChanged,
      maxPrice,
    } = Categories();
    getBanner();
    maxPrice();

    let sort = store.getters.getIsRank ? 'popularity' : null;
    onSortChanged(sort);

    const currentShipping = computed(() => store.getters.getSelectedAddress);

    watch(currentShipping, () => {
      if(store.getters.getAddressChange) {
        store.dispatch("setAddressChange", false);
        onSortChanged(sort);
      }
    });

    return {
      imgBaseUrl,
      banner,
      category,
      onSortChanged,
      onStockChanged,
      onFilterChanged,
      onPriceRangeChanged,
    };
  },
};
</script>

<style lang="scss" scoped>
.collection-banner-container {
  background-image: url("/images/lines-pattern.png");
}
</style>
